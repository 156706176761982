export const ROUTER_CONSTANTS = {
  PUBLIC: {
    CREATE_ORGANIZATION_FORM: {
      PATH: 'create-organization-form',
    },
  },
  TEST_RENDERER: {
    PATH: 't',
    CHILDREN: {
      DEFAULT: {
        PATH: '',
        PARAMS: {
          MODE: 'mode',
          ID: 'id',
        },
      },
      WRONG_LINK: {
        PATH: 'wrong-link',
      },
    },
  },
};
